import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/HomePage/HeroSection"
import FeaturedArticles from "../components/HomePage/FeaturedArticles"
import BakingSection from "../components/HomePage/BakingSection"

const IndexPage = () => (
  <Layout activePage="home">
    <HeroSection />
    <FeaturedArticles />
    <BakingSection />
  </Layout>
)
export default IndexPage

export const Head = () => (
  <>
    <SEO
      title="Home"
      description="Follow Zach Levy and his baking and electronics projects."
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap"
      rel="stylesheet"
    />
    <style>
      {" "}
      @import
      url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');{" "}
    </style>
  </>
)
