import React from "react"
import {
  Hero,
  ProfileImageWrapper,
  ProfileTitle,
  ProfileSubtitle,
  BioContainer,
  BioSeperatorBlock,
  ProfileBody,
  SocialMediaIconsContainer,
  SocialIconWrapper,
  AboutButton,
} from "./HeroElements"
import img from "../../../images/lake-hero.jpg"
import { StaticImage } from "gatsby-plugin-image"
import { HiOutlineMail } from "react-icons/hi"
import { FaGithub, FaLinkedinIn } from "react-icons/fa"
import { SiGithub } from "react-icons/si"
import { IconContext } from "react-icons/lib"

function HeroSection() {
  return (
    <>
      <Hero image={img}>
        <BioContainer>
          <ProfileTitle>Zach Levy</ProfileTitle>
          <BioSeperatorBlock />
          <ProfileBody>
            Studying Electrical Engineering at UW-Madison
          </ProfileBody>
          <SocialMediaIconsContainer>
            <IconContext.Provider value={{ color: "#fff" }}>
              <SocialIconWrapper
                href="mailto:zach@zalevy.com"
                color={"#0078d4"}
                padding={"4px"}
              >
                <HiOutlineMail size={52} strokeWidth={1} />
              </SocialIconWrapper>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "#fff" }}>
              <SocialIconWrapper
                href="https://linkedin.com/in/zach-levy-a674461a7"
                color={"#0077B5"}
                padding={"10px"}
              >
                <FaLinkedinIn size={40} strokeWidth={1} />
              </SocialIconWrapper>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "#fff" }}>
              <SocialIconWrapper
                href="https://github.com/zvy357"
                color={"#000"}
                padding={"5px"}
              >
                <SiGithub size={50} strokeWidth={0} />
              </SocialIconWrapper>
            </IconContext.Provider>
          </SocialMediaIconsContainer>
        </BioContainer>
        <ProfileImageWrapper to="/about">
          <StaticImage
            src="../../../images/ZachPortrait.jpg"
            alt="Portrait of Zach Levy"
            width={1000}
            height={1000}
            imgStyle={{ borderRadius: "50%" }}
          />
        </ProfileImageWrapper>
      </Hero>
    </>
  )
}
export default HeroSection
