import React from "react"
import { getImage } from "gatsby-plugin-image"
import {
  ArticlesContainer,
  ArticleSectionButton,
  ArticleSectionTitle,
  FeaturedArticlesContainer,
  FiltersContainer,
  FilterBox,
} from "./FeaturedArticlesElements"
import ArticleCard from "../../ArticleCard"
import { graphql, useStaticQuery } from "gatsby"
import { size, isInSizeRange } from "../../../../devices"

function FeaturedArticles() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMdx(
        filter: {
          frontmatter: {
            slug: {
              in: ["cake-stencil-guide", "ultrasonic-array", "website-details"]
            }
          }
        }
        sort: { order: DESC, fields: frontmatter___date_published }
      ) {
        nodes {
          id
          frontmatter {
            title
            slug
            tags
            description
            featured_image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 1000
                  height: 1000
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  `)
  const articles = data.allMdx.nodes

  let count = 0

  return (
    <>
      <FeaturedArticlesContainer>
        <ArticleSectionTitle>Featured Articles</ArticleSectionTitle>
        <ArticlesContainer>
          {articles.map(article => {
            count++
            return (
              <ArticleCard
                image={getImage(article.frontmatter.featured_image)}
                title={article.frontmatter.title}
                description={article.frontmatter.description}
                key={article.id}
                height={200}
                width={200}
                to={"/articles/" + article.frontmatter.slug}
                tags={article.frontmatter.tags}
                visible={true}
              />
            )
          })}
        </ArticlesContainer>
        <ArticleSectionButton to="/articles">
          See All articles
        </ArticleSectionButton>
      </FeaturedArticlesContainer>
    </>
  )
}

export default FeaturedArticles
