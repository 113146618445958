import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../../devices"
import ArticleCard from "../../ArticleCard"
import { ArticleBox } from "../../ArticleCard/ArticleCardElements"

export const FeaturedArticlesContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: calc(100vh - 74px + 10px);
  background: repeating-linear-gradient(
    45deg,
    #ddd,
    #ddd 20%,
    #fff 20%,
    #fff 40%
  );
  overflow: none;
  box-sizing: border-box;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 20px;
  padding: 20px 0;

  @media ${device.mobile} {
    height: auto;
  }
`

export const ArticlesContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 6vw;
  padding: 0 40px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 0 20px;
    overflow: none;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;

    ${ArticleBox}:nth-child(3) {
      display: none;
    }
  }
`

export const ArticleSectionTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 300;
  padding: 8px 20px;
  border: 4px solid rgb(115, 32, 135);
  color: #222;
  border-radius: 16px;

  @media ${device.mobile} {
    margin: 40px 20px;
    border: 6px solid rgb(115, 32, 135);
  }
`

export const ArticleSectionButton = styled(Link)`
  background: #222;
  font-size: 1.6rem;
  color: #fff;
  border-radius: 12px;
  transition: 0.3s;
  border: 4px solid rgb(0, 153, 153);
  text-decoration: none;
  padding: 8px 20px;

  &:hover {
    border: 4px solid aqua;
    box-shadow: 0px 6px 12px 0px rgb(0, 0, 0, 0.6);
  }

  @media ${device.mobile} {
    margin: 40px;
  }
`
