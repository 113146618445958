import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../../devices"

export const BakingSectionContainer = styled.div`
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 74px + 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.mobile} {
    height: auto;
  }
`

export const BakingImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-start: 1;
  grid-row-end: 3;
  gap: 30px;
  white-space: nowrap;
  margin: 5%;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
      & .baking-grid-images:nth-child(n+9)    {
      display: none;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  @media ${device.desktop} {
    & .baking-grid-images:nth-child(n+9)    {
      display: none;
    }
  }

`

export const BakingSectionTextContainer = styled.div`
  grid-column: span 2;
  height: 100%;
  order: 6;
  width: 100%;
  backface-visibility: hidden; /* Fix Scaling Issue */

  @media ${device.mobile} {
    order: 2;
  }
`
export const BakingSectionText = styled(Link)`
  padding-right: 10px;
  border: 6px solid rgb(0, 153, 153);
  border-radius: 20px;
  padding-top: 2%;
  padding-bottom: 6%;
  padding-left: 10px;
  color: #fff;
  transition: 0.3s;
  text-decoration: none;
  background-color: #222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    box-shadow: 0px 6px 12px 0px rgb(0, 0, 0, 0.6);
    border: 6px solid aqua;
  }
`
export const BakingSectionTitle = styled.h1`
  font-size: 4.5vw;
  font-family: 'Dancing Script', cursive;

  @media ${device.mobile} {
    font-size: 3rem;
  }
`

export const BakingSectionSeperator = styled.div`
  width: 100%;
  border-radius: 20px;
  height: 8px;
  margin-top: 2px;
  margin-bottom: 12px;
  background-color: #fff;
  transition: 0.3s;
`

export const BakinSectionSubtitle = styled.h2`
  font-size: 1.6rem;
  font-weight: lighter;
  font-style: italic;
  font-family: 'Dancing Script', cursive;
  white-space: normal;
`
