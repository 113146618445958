import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../../devices"

export const Hero = styled.div`
  min-height: calc(100vh - 74px + 10px);
  font-size: 4rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props => props.image}) center center/cover no-repeat;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;

  @media ${device.mobile_tablet} {
    flex-direction: column-reverse;
  }
`

export const ProfileImageWrapper = styled(Link)`
  width: 30%;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 6px solid rgb(0, 153, 153);
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 6px 12px 0px rgb(0, 0, 0, 0.6);
    border: 6px solid aqua;
    transform: scale(1.02);
  }

  @media ${device.mobile_tablet} {
    width: 80%;
    max-width: 40vh;
    aspect-ratio: 1;
  }
`

export const BioContainer = styled.div`
  width: 30%;
  text-align: left;

  @media ${device.mobile_tablet} {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ProfileTitle = styled.h1`
  font-weight: 100;
  font-size: 4rem;
  color: #fff;

  @media ${device.mobile_tablet} {
    font-size: 3.5rem;
    text-align: center;
  }
`
export const BioSeperatorBlock = styled.div`
  width: 40%;
  height: 24px;
  background: rgb(115, 32, 135);
  margin-top: 4%;
  margin-bottom: 4%;
  border-radius: 12px;
`

export const ProfileSubtitle = styled.h2`
  font-weight: 100;
  font-size: 3rem;
  color: #eee;
`

export const ProfileBody = styled.p`
  font-weight: 100;
  font-size: 1.4rem;
  color: #fff;
  text-align: left;
  margin-bottom: 12px;

  @media ${device.mobile_tablet} {
    font-size: 1rem;
    text-align: center;
  }

  @media ${device.tablet} {
    font-size: 1.4rem;
    text-align: center;
  }
`

export const AboutButton = styled.a`
  background: #222;
  font-size: 1vw;
  color: rgb(0, 153, 153);
  border-radius: 10px;
  transition: 0.3s;
  border: 2px solid transparent;
  text-decoration: none;
  padding: 4px 20px;

  &:hover {
    border: 2px solid aqua;
    color: aqua;
  }
`

export const AboutButtonText = styled.p`
  border: 2px solid aqua;
  color: aqua;
  width: 60px;
`

export const SocialMediaIconsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: left;
  gap: 6%;

  @media ${device.mobile_tablet} {
    width: 80%;
    justify-content: center;
  }
`

export const SocialIconWrapper = styled.a`
  svg {
    margin: 0;
    padding: 0;
    stroke-width: "${props => props.thickness}";
  }

  background-color: ${props => props.color};
  padding: ${props => props.padding};
  overflow: hidden;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  overflow: auto;
  transition: 0.2s;
  &:hover {
    box-shadow: 0 4px 6px 0 rgb(0, 0, 0, 0.6);
    transform: scale(1.05);
  }
`
