import React from "react"
import {
  BakingSectionContainer,
  BakingSectionTextContainer,
  BakingSectionText,
  BakingSectionTitle,
  BakinSectionSubtitle,
  BakingSectionSeperator,
  BakingImageGrid,
  BakingImageSection,
  BakingImageWrapper,
} from "./BakingSectionElements"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import "./gridImageStyles.css"

function BakingSection() {
  const data = useStaticQuery(graphql`
    query BakingImageQuery {
      allFile(
        filter: {
          relativeDirectory: { eq: "baking-images" }
          name: {
            in: [
              "BatCake"
              "LemonCake"
              "BlackAndWhite"
              "LemonCakeSlice"
              "Scones"
              "TulipCakeSlice"
              "CinnamonRoll"
              "Macaron"
              "AppleDonuts"
              "CelticKnotCake"
            ]
          }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 600
              height: 600
              transformOptions: { cropFocus: CENTER }
            )
          }
          id
          name
        }
      }
    }
  `)
  let count = 0
  return (
    <>
      <BakingSectionContainer>
        <BakingImageGrid>
          {data.allFile.nodes.map(item => {
            count++
            return (
              <GatsbyImage
                image={item.childImageSharp.gatsbyImageData}
                key={item.id}
                height={600}
                width={600}
                style={{ order: count, borderRadius: "20px" }}
                className="baking-grid-images"
                imgStyle={{ borderRadius: "20px" }}
                alt={item.name}
              />
            )
          })}
          <BakingSectionTextContainer>
            <BakingSectionText to="/baking">
              <BakingSectionTitle>Baking Creations</BakingSectionTitle>
              <BakingSectionSeperator />
              <BakinSectionSubtitle>
                Check out my latest cakes, cookies, and more!
              </BakinSectionSubtitle>
            </BakingSectionText>
          </BakingSectionTextContainer>
        </BakingImageGrid>
      </BakingSectionContainer>
    </>
  )
}

export default BakingSection
